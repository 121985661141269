import React, { useState, useEffect } from "react";
import FormPage, { CustomGridLayout } from "../../shared/Layouts/FormPage";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { disableUser, getUser } from "../../redux/actions/userAction";
import { Accordion, AccordionTab } from "primereact/accordion";
import { CustomRadioButtons } from "../../shared/AllInput";
import { allValidations } from "../../utils/formValidations";
import moment from "moment";

const ViewUser = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        countryCode: "",
        mobile: "",
        image: "",
        city: "",
        state: "",
        address: "",
        pincode: "",
        vehicle: [],
        isBlocked: true,
    });

    useEffect(() => {
        if (id) {
            dispatch(
                getUser(id, (data) => {
                    setData({
                        userId: data.userId,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        countryCode: data.countryCode,
                        mobile: data.mobile,
                        image: data.image,
                        city: data.city,
                        state: data.state,
                        address: data.address,
                        pincode: data.pincode,
                        vehicle: data.vehicle,
                        isBlocked: data.isBlocked === true ? { name: "Yes", key: true } : { name: "No", key: false },
                    });
                })
            );
        }
    }, [id, dispatch]);

    const handleSwitchChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
        if (name === "isBlocked") {
            dispatch(disableUser(id, value.key));
        }
    };

    let blockedOptions = [
        { name: "Yes", key: true },
        { name: "No", key: false },
    ];

    return (
        <>
            <FormPage>
                <CustomGridLayout>
                    <div className="col-12 md:col-4">
                        <div className="card">
                            <div className="flex align-items-center justify-content-between">
                                <h3>User Information </h3>
                                <div className="flex align-items-center"></div>
                            </div>
                            <CustomRadioButtons
                                col={12}
                                options={blockedOptions}
                                optionLabel={"name"}
                                value={data.isBlocked}
                                label="Is Blocked"
                                name="isBlocked"
                                data={data}
                                onChange={handleSwitchChange}
                            />

                            {/* <div className="flex align-items-center justify-content-end mx-2">
                                Block
                                <InputSwitch checked={data?.disableStatus} onChange={(e) => handleSwitchChange(e)} className="mx-1" />
                            </div> */}
                            <hr />
                            <div className="grid">
                                <p className="col-12">userId: {data.userId ?? ""}</p>
                                <p className="col-12">Name: {`${data?.firstName ?? ""} ${data?.lastName ?? ""}`}</p>
                                <p className="col-12">
                                    Email: <a href={`mailto:${data?.email}`}> {data?.email}</a>
                                </p>
                                <p className="col-12">
                                    Mobile: <a href={`tel:${data?.mobile}`}> {data?.mobile}</a>
                                </p>
                                <p className="col-12">
                                    Address: {data?.city} {data?.address} {data?.state},{data?.pincode}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-8 mx-auto ">
                        <div className="">
                            <Accordion multiple className="mt-3" activeIndex={[0, 1]}>
                                {data?.vehicle.map((vehicle, index) => (
                                    <AccordionTab className="" header={`Vehicle Details ${index + 1}`}>
                                        <p>Vehicle Model: {vehicle.vehicleModel?.name}</p>
                                        <p> Vehicle Name: {vehicle.vehicleBrand?.name}</p>
                                        <p> Vehicle Number: {vehicle.vehicleNumber}</p>
                                        {vehicle?.subscriptions?.map((plan) => (
                                            <>
                                                <p> Plan Name: {plan?.planDetails?.name}</p>
                                                <p> Plan Amount: ₹{plan?.planDetails?.amount}</p>
                                                <p> Plan Starts: {moment(plan?.startAt).format("DD-MM-YYYY")}</p>
                                                <p> Current Plan Ends: {moment(plan?.currentEnd).format("DD-MM-YYYY")}</p>
                                                <p> Plan Status: {plan?.status}</p>
                                            </>
                                        ))}
                                    </AccordionTab>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </CustomGridLayout>
            </FormPage>
        </>
    );
};

export default ViewUser;
